@import 'variables';
@import '3rdParty/index';
@import 'components/index';
@import 'flexbox/index';
@import 'project/index';
.clickable {
    cursor: pointer;
}

.not-allowed {
    cursor: not-allowed;
}

.not-clickable {
    pointer-events: none;
}

.font-1-1rem {
    font-size: 1.1rem;
}

body {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
}

.actions a {
    text-decoration: none;
}

.actions i {
    color: #71848c;
    font-size: 13px;
    margin-right: 5px;
}

.actions i:hover {
    color: #4f5d63;
}

.help-text-tooltip {
    color: '#ffffff';
}

.fa-info-circle:before {
    content: "";
}

.form-inline .custom-control {
    align-items: center;
    justify-content: center;
}

.form-inline .custom-control-label {
    margin-bottom: 0;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: $primary;
    background-color: $primary;
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: $primary;
}

.assessment-from .input-container input.input {
    background: #fff !important;
}

.assessment-from .input-container input[type=radio] {
    width: auto;
}

.assessment-from .input-container select.input {
    height: 38px;
    border-radius: 20px;
    border: 1px solid rgba(18, 51, 64, 0.25);
    outline: none;
    box-shadow: none;
    width: 100%;
    padding: 5px 42px 5px 10px;
    background: url(/images/arrow-bottom.svg) #fff right center no-repeat;
    background-size: 33px;
}

.font-weight-600 {
    font-weight: 600 !important;
}

.text-dark-blue {
    color: #123340 !important;
}

#accordion-container .assessment-accordion .card {
    margin-bottom: 20px;
    border: 0;
    padding: 0;
    border: 1px solid var(--primary);
    border-radius: 30px;
    background: #fff;
    border-radius: 23px;
}

.custom-control {
    position: relative;
    z-index: 1;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
}

.custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;
}

.custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: 1px solid #adb5bd;
}

.custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50%/50% 50% no-repeat;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: var(--primary);
    background-color: var(--primary);
}

.custom-checkbox {
    .custom-control-input:checked~.custom-control-label::after {
        background-image: url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'8\' height=\'8\' viewBox=\'0 0 8 8\'%3e%3cpath fill=\'%23fff\' d=\'M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z\'/%3e%3c/svg%3e');
    }
}

.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
}

.custom-checkbox .custom-control-label::before {
    border-radius: 0.25rem;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.custom-radio .custom-control-label::before {
    border-radius: 50%;
}

.custom-radio {
    .custom-control-input:checked~.custom-control-label::after {
        background-image: url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'12\' height=\'12\' viewBox=\'-4 -4 8 8\'%3e%3ccircle r=\'3\' fill=\'%23fff\'/%3e%3c/svg%3e');
    }
}

.accordion>.card {
    overflow: hidden;
}

.custom-control-inline {
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-right: 1rem;
}

.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
}

#accordion-container .assessment-accordion .card .card-body {
    padding-top: 10px;
}

#accordion-container .assessment-accordion .card .btn:focus,
#accordion-container .assessment-accordion .card .btn.focus {
    outline: 0;
    box-shadow: none;
}

#accordion-container .assessment-accordion .card .card-header {
    border: 0;
    padding: 0;
}

#accordion-container .assessment-accordion .card .card-header .btn-header-link {
    color: $dark-blue;
    display: block;
    text-align: left;
    background: #fff;
    color: #222;
    padding: 10px 1.25rem;
    font-size: 16px;
}

#accordion-container .assessment-accordion .card .card-header .btn-header-link:after {
    content: "\f106";
    font-family: "Font Awesome 5 Pro";
    font-weight: 500;
    float: right;
    font-size: 20px;
    margin-right: 0px;
    margin-top: -2px;
    color: $dark-blue;
}

#accordion-container .assessment-accordion .card .card-header .accordion-title {
    width: 100%;
}

#accordion-container .assessment-accordion .card .card-header .btn-header-link .actions-accordion {
    margin-left: 5px;
    width: 100px;
}

#accordion-container .assessment-accordion .card .card-header .btn-header-link .actions-accordion i {
    color: #71848C;
    font-size: 13px;
    margin-left: 5px !important;
    cursor: pointer !important;
    padding: 4px;
}

#accordion-container .assessment-accordion .card .card-header .btn-header-link .actions-accordion i:hover {
    opacity: 0.7;
}

body .actions-accordion i {
    cursor: pointer !important;
}

#accordion-container .assessment-accordion .card .card-header .btn-header-link.collapsed {
    background: #fff;
    color: $dark-blue;
}

#accordion-container .assessment-accordion .card .card-header .btn-header-link.collapsed:after {
    content: "\f107";
}

#accordion-container .assessment-accordion .card .card-body {
    padding-top: 10px;
}

#accordion-container .assessment-accordion .card .card-body p {
    color: $dark-blue;
    font-size: 15px !important;
    font-style: normal;
    font-weight: 400;
}

#accordion-container .assessment-accordion .card .collapsing {
    line-height: 30px;
}

#accordion-container .assessment-accordion .card .collapse {
    border: 0;
}

#accordion-container .assessment-accordion .card .collapse.show {
    line-height: 30px;
    color: #222;
}

.not-arrow {
    cursor: context-menu !important;
}

.not-arrow::after {
    display: none !important;
}

.fade-white {
    background: #f2f2f2 !important;
}

.assessment-details .card i {
    color: var(--secondary);
}

.custom-icon-color i {
    color: var(--secondary);
}

@media print {
    .table-responsive .table thead td,
    .table-responsive .table th {
        padding: 0;
        &:first-child {
            border-top-left-radius: 10px;
        }
        span {
            display: block;
            padding: 10px;
            width: 100%;
            height: 100%;
            background-color: var(--primary-color);
        }
    }
    @page {
        margin: 0mm;
        size: auto;
    }
    body {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
    nav {
        display: none !important;
        >* {
            display: none;
        }
    }
    button {
        display: none !important;
    }
    .sidebar {
        display: none;
    }
    * {
        //display: none;
        .display-print {
            display: block !important;
        }
    }
    .print-hidden {
        display: none !important;
    }
    main {
        page-break-after: always !important;
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        margin: 0 !important;
        padding: 20px !important;
        margin-left: 0 !important;
    }
}


/* new-og-chart-tree start */
.new-og-chart-tree-main {
    display: flex;
    flex-wrap: nowrap;
    width: auto;
    padding-bottom: 30px;
    overflow-x: scroll;
    overflow-y: hidden;
    min-height: 66vh;
  }
  
  .new-og-chart-tree-main-2 {
    min-height: 80vh;
  }
  
  .tree-new-oc-main {
    display: contents;
  }
  
  .new-og-chart-tree-main ul {
    margin-top: -7px;
    display: flex;
    justify-content: center;
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }
  
  .new-og-chart-tree-main li {
    float: left;
    text-align: center;
    list-style-type: none;
    position: relative;
    padding: 30px 10px 0 10px;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
  }
  
  /* .new-og-chart-tree-main li:only-child {
      padding-top: 0;
      margin-left: -40px;
  }
  .new-og-chart-tree-main li:before, .new-og-chart-tree-main li:after {
      content: "";
      position: absolute;
      top: 0;
      right: 50%;
      border-top: 1px solid #7C98B6;
      width: 50%;
      height: 20px;
  }
  .new-og-chart-tree-main li::after {
      right: auto;
      left: 50%;
      border-left: 1px solid #7C98B6;
  } */
  .new-og-chart-tree-main li a {
    text-decoration: none;
    color: #666;
    font-family: arial, verdana, tahoma;
    font-size: 11px;
    display: inline-block;
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    position: relative;
  }
  
  .hide-and-show-tree-user {
    position: absolute;
    bottom: -8px;
    left: 1px;
    right: 0;
    margin: 0 auto;
    background: rgba(0, 0, 0, 0.3803921569);
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: none;
    color: #fff;
    font-size: 10px;
    opacity: 1;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0;
  }
  
  .hide-and-show-tree-user i {
    line-height: 12px;
  }
  
  .oc-box-option-icon .fa-chevron-up.hide {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  
  .tree-hide-user {
    display: none !important;
    transition: all 0.5s;
  }
  
  .tree-flex-user {
    display: flex !important;
    transition: all 0.5s;
  }
  
  .new-og-chart-tree-main li a:hover .hide-and-show-tree-user {
    opacity: 1;
  }
  
  .new-og-chart-tree-box .new-oc-user-pic img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid #F6FBFF;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
  }
  
  /* .new-og-chart-tree-box .new-oc-user-pic {
      width: 40px;
      height: 40px;
      border-radius: 50%;
  } */
  .new-og-chart-tree-box {
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    position: relative;
    z-index: 1;
    font-size: 18px;
    padding: 14px 18px;
    border-radius: 10px;
    border: 1px solid #194B60;
    min-width: 100px;
  }
  .new-og-chart-tree-box .actions {
    display: none;
    color: #71848C;
    font-size: 10px;
    position: absolute;
    right: 5px;
    top: 2px;
  }
  .new-og-chart-tree-box .actions i {
    color: #71848C;
    margin-right: 2px;
    cursor: pointer;
  }
  .new-og-chart-tree-box .actions i:hover {
    opacity: 0.5;
  }
  .new-og-chart-tree-box:hover .actions {
    display: block;
  }
  
  .new-og-chart-tree-box:hover {
    transform: translateY(-2px);
    /* transform: scale(1.04); */
  }
  
  .new-og-chart-tree-box:hover .new-oc-user-pic img {
    transform: scale(1.2);
  }
  
  .new-og-chart-tree-box:hover .new-oc-user-content {
    box-shadow: 0px 4px 7px rgba(52, 52, 52, 0.05);
  }
  
  .new-og-chart-tree-box .new-oc-user-content {
    background: #fff;
    text-align: center;
    padding: 22px 6px 12px 6px;
    width: 200px;
    border-top: 4px solid #ccc;
    border-radius: 4px;
    margin-top: -20px;
  }
  
  .new-og-chart-tree-box .new-oc-user-content h6 {
    font-size: 14px;
    font-family: "Nunito Bold";
    line-height: 19px;
    color: #000;
    margin-bottom: 3px;
  }
  
  .new-og-chart-tree-box .new-oc-user-content p {
    font-size: 12px;
    font-family: "Nunito Regular";
    line-height: 17px;
    color: #85869B;
    margin-bottom: 0;
  }
  
  .new-og-chart-user-score {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  
  .new-oc-user-content .new-og-chart-user-score {
    background: #FAFAFA;
    padding: 2px 2px 2px 20px;
    display: inline-flex;
    border-radius: 20px;
  }
  
  .new-og-chart-user-score p {
    margin-bottom: 0;
  }
  
  .new-og-chart-user-score .dot-div {
    margin-left: 10px;
    font-family: "Nunito Bold";
    border-radius: 30px;
    padding: 3px 10px !important;
  }
  
  /* start */
  .tree-new-oc-main ul {
    padding-top: 40px;
    position: relative;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
  }
  
  .tree-new-oc-main li {
    float: left;
    text-align: center;
    list-style-type: none;
    position: relative;
    padding: 50px 10px 0 10px;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
  }
  
  .tree-new-oc-main li::before, .tree-new-oc-main li::after {
    content: "";
    position: absolute;
    top: 0;
    right: 50%;
    border-top: 2px solid #194B60;
    width: 50%;
    height: 44px;
  }
  
  .tree-new-oc-main li:first-child::before {
    width: 0;
  }
  
  .tree-new-oc-main li::after {
    right: auto;
    left: 50%;
    border-left: 2px solid #194B60;
  }
  
  .tree-new-oc-main li:only-child::after, .tree-new-oc-main li:only-child::before {
    display: none;
  }
  
  .tree-new-oc-main li:only-child {
    padding-top: 0;
  }
  
  .tree-new-oc-main ul ul li:only-child {
    padding-top: 6px;
    margin-left: -37px;
  }
  
  .tree li:first-child::before, .tree-new-oc-main li:last-child::after {
    border: 0 none;
  }
  
  .tree-new-oc-main li:last-child::before {
    border-right: 2px solid #194B60;
    border-radius: 0 25px 0 0;
    -webkit-border-radius: 0 25px 0 0;
    -moz-border-radius: 0 25px 0 0;
  }
  
  .tree-new-oc-main li:first-child::after {
    border-radius: 25px 0 0 0;
    -webkit-border-radius: 25px 0 0 0;
    -moz-border-radius: 25px 0 0 0;
  }
  
  .tree-new-oc-main ul ul::before {
    content: "";
    position: absolute;
    top: 7px;
    left: 50%;
    border-left: 2px solid #194B60;
    width: 0;
    height: 33px;
  }
  
  .tree-new-oc-main ul ul li a {
    position: relative;
  }
  
  .tree-new-oc-main ul ul li a:after {
    position: absolute;
    font-size: 13px;
    content: "\f078";
    color: #194B60;
    transition: all 0.25s;
    font-family: "Font Awesome 5 pro";
    top: -18px;
    left: 2px;
    right: 0;
  }
  
  .tree-new-oc-main ul ul li:last-child a:after {
    left: -1px;
  }
  
  /* .tree-new-oc-main ul ul li:first-child a:after {
      left: 2px;
  } */
  .tree-new-oc-main li a:hover, .tree-new-oc-main li a:hover + ul li a {
    /* background: #c8e4f8; color: #000; border: 1px solid #94a0b4; */
  }
  
  .tree-new-oc-main li a:hover + ul li::after,
  .tree-new-oc-main li a:hover + ul li::before,
  .tree-new-oc-main li a:hover + ul::before,
  .tree-new-oc-main li a:hover + ul ul::before {
    /* border-color:  #94a0b4; */
  }
  
  .tree-new-oc-main ul.single-user {
    padding-left: 500px;
    padding-top: 10px;
  }
  
  .tree-new-oc-main ul.single-user::before {
    height: 100%;
  }
  
  .tree-new-oc-main ul.single-user::after {
    content: "";
    position: absolute;
    top: 46px;
    left: 50%;
    border-bottom: 2px solid #194B60;
    width: 135px;
    border-radius: 0px 0px 0px 25px;
    height: 25px;
  }
  
  .tree-new-oc-main .single-user li:only-child {
    padding-top: 0px;
    margin-left: 0px;
  }
  
  .tree-new-oc-main .left-arow-icon::after {
    content: "\f054";
    font-family: "Font Awesome 5 pro";
    top: 51px;
    left: -20px !important;
    right: unset;
  }
  
  /* new-og-chart-tree End */
  .project-details .input-container input.input, .project-details .input-container select.input {
    background-color: #E8F0FE !important;
  }
  
  .zoom-and-zoom-out-tree {
    background: #fff;
    border-radius: 6px;
    display: inline-block;
    position: fixed;
    right: 30px;
    top: calc(50% + 65px - 17px);
    z-index: 999;
    box-shadow: 0px 4px 10px rgba(52, 52, 52, 0.05);
  }
  
  .zoom-and-zoom-out-tree ul {
    margin: 0;
    padding: 0;
  }
  
  .zoom-and-zoom-out-tree ul li {
    list-style-type: none;
  }
  
  .zoom-and-zoom-out-tree ul li a {
    color: #797A93 !important;
    padding: 8px 7px;
    border: 1px solid transparent;
    border-radius: 6px;
    background: #fff;
    font-size: 18px;
    line-height: 18px;
    position: relative;
    display: block;
  }
  .zoom-and-zoom-out-tree ul li a i {
    color: #797A93 !important;
  }
  
  .zoom-and-zoom-out-tree ul li a:hover {
    color: #090B3C !important;
  }
  
  /*# sourceMappingURL=style.css.map */

  .custum-clr-btn{

    color: var(--primary);
    background-color: var(--secondary) !important;
    
  }
  .zoom-controled {
                        
    // position: fixed;
    // top: 70%;
    // bottom: 10px;
    // right:50px;
    // transform: translate(-50%, -50%);
    display: inline;
    // flex-direction: column;
    // align-items: center;
    // z-index: 1000;
  }

  .zoom-and-zoom-out-trees {
    display: flex;
    flex-direction: column;
  }

  .btn-styled {
    margin-bottom: 5px;
    cursor: pointer;
  }

  // .input-container.selected {
  //   background-color: lightblue; 
  // }
  .fullscreen-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 400; /* Ensure the modal is above other elements */
  }
  .vertical-scrollable {
    max-height: 600px; /* Adjust the height as needed */
    overflow-y: auto;
  }

  .introduction-widget {
    padding: 80px 50px 80px 50px;
    border-radius: 30px;
    min-height: 200px;
    background: #fff;
    margin-right: 25px;
    h5 {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #C4CCCF;
        line-height: 1px;
        margin: 40px 0;
        font-size: 13px;
    }
    h5 span {
        background: #fff;
        padding: 0 10px;
    }
}
  