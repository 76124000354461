
.btn {
    border-radius: $button-border-radius;
    color: $secondary;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 15px;

    &-primary {
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 2px 1px -1px rgba(0, 0, 0, 0.03), 0 1px 1px 0 rgba(0, 0, 0, 0.04);
        background-color: $secondary;
        border: 1px solid $secondary;
        color: white;
        &:hover {
            background-color: $primary !important;
            border: 1px solid $primary!important;
        }
        &:focus {
            border: 1px solid hsla($secondary, 10) !important;
            background-color: $secondary;
            box-shadow: none !important;
            outline: none;

        }
        &:active {
            background-color: $secondary !important;
        }
        &:disabled {
            background-color: $secondary;
            border: 1px solid $secondary;
        }
    }

    &-secondary {
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 2px 1px -1px rgba(0, 0, 0, 0.03), 0 1px 1px 0 rgba(0, 0, 0, 0.04);
        background-color: white;
        border: 1px solid white;
        color: $dark-blue;
        &:hover {
            background-color: darken(#fff, 1);
            border: 1px solid darken(#fff, 1);
            color: $dark-blue;
        }
    }

    &-sm {
        font-size: 13px;
    }
}

.btn_border{
    color: var(--secondary) !important;
    border: 1px solid $secondary-200-solid !important;
    border-radius: $button-border-radius !important;
}

@include media-breakpoint-up(lg) {
    .btn {
        &-sm {
            height: 28px;
        }
    }
}