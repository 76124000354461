@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/variables';
// COLORS
$secondary: var(--secondary);
$secondary-200: rgba($secondary, 0.2);
$secondary-200-solid: #ddf3f5;
$snow-white: #fffafb;
$snow-white-200: rgba($snow-white, 0.2);
$alpha:0.3;
// $primary: #267493;
$primary:var(--primary);
$primary-200: $primary;
$link-color: var(--link-primary);
$brand-primary: var(--primary);
$dark-blue: #123340;
$dark-blue-200: rgba(18, 51, 64, 0.25);
$dark-blue-200-solid: #ccd3d6;
$dark-blue-600: rgba(18, 51, 64, 0.6);
//RAG COLORS
$green: #57c4ad;
$green-200: #ddf3ef;
$amber: #eda247;
$amber-200: #fbecda;
$amber-green: #dac266;
$amber-green-200: rgb(218, 194, 102, 0.2);
$red-amber: #e7836d;
$red-amber-200: #fae6e2;
$red: #db4325;
$red-200: #f8d9d3;
$light-grey: #e7eef1;
$light-grey-200: rgba(231, 238, 241, 0.2);
$light-grey-200-solid: #ccd3d6;
//Overall Colors BASIC
$brand-secondary: #2c2733;
$text: #333;
// Font sizes
$base-font-size: 0.95em;
// Border Radius
$base-border-radius: 6px;
$button-border-radius: 20px;
$card-border-radius: 25px;
//Input component variables
$alert-danger-border: #c70000;
$input-line-height: 25px;
$input-font-size: inherit;
$input-font-weight: 200;
$input-border-color: rgb(224, 224, 224);
$input-placeholder-color: rgb(224, 224, 224);
$input-border-highlight-color: rgb(0, 188, 212);
//Panel component variables
$panel-heading-height: 50px;
$panel-bg: #fff;
$panel-heading-bg: #f7f7f7;
$panel-heading-color: #333;
$panel-heading-border: 1px solid #f1f1f1;
$panel-icon-color: #36a39c;
$panel-icon-border-color: #ddd;
$panel-icon-background-color: #fff;
$panel-alt-heading-bg: #36a39c;
$panel-alt-heading-color: #fff;
$large-border-radius: 12px;
//Tab component styles
$tab-background: transparent;
$tab-background-hover: transparent;
$tab-background-active: #1ac0c6;
$tab-active-background: transparent;
$tab-inactive-color: lighten(black, 20);
$tab-active-color: #55c5cd;
$tab-line-color: $secondary;
//Toast component styles
$toast-bg: rgba(0, 0, 0, 0.9);
$toast-color: white;
//Scaffolding
$breakpoints: ( mobile-small: 320px, mobile: 375px, handheld-device: 576px, tablet: 768px, tablet-large: 992px, desktop: 1200px, );
//HelpText
$tool-tip-background : var(--tooltip-bg); 
$tool-tip-text : var(--tooltip-text); 
$icon-color :var(--icon-color);
