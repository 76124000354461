@import '../_variables';

.table-responsive {
  background: #ffffff;
  border: 1px solid $dark-blue-200;
  border-radius: 25px;
  .table {
    margin-bottom: 0;
    thead td, th {
      border-radius: 0 !important;
      border-top: none;
      border-bottom: 1px solid $dark-blue-200;
      color: $dark-blue;
      font-size: 13px;
      font-weight: bold;
      background-color: $primary;
      background: $primary;
      color: white;
    }
    td {
      vertical-align: middle;
      border-top: none;
    }

    &-striped {
      tbody tr:nth-of-type(odd) {
        background-color: $light-grey;
      }
    }
  }
}

.striped {
  background-color: $light-grey;
}

.table {
  &-actions-question {
    width: 30%;
    border-right: 1px solid $light-grey-200-solid;
    border-bottom: 1px solid $light-grey-200-solid;
  }
  &-actions-rag {
    width: 8%;
    border-right: 1px solid $light-grey-200-solid;
    border-left: 1px solid $light-grey-200-solid;
    border-bottom: 1px solid $light-grey-200-solid;
  }
  &-actions-success-factor {
    width: 22%;
    border-right: 1px solid $light-grey-200-solid;
    border-left: 1px solid $light-grey-200-solid;
    border-bottom: 1px solid $light-grey-200-solid;
  }
  &-actions-action {
    width: 40%;
    border-left: 1px solid $light-grey-200-solid;
    border-bottom: 1px solid $light-grey-200-solid;
  }
  &__rag {
    .table thead th,  .table thead td {
      vertical-align: middle;
      background-color: $primary;
      background: $primary;
      color: white;
    }
    .row-light {
      background-color: $light-grey;
      color: $dark-blue;
      font-weight: 600;
      td {
        color: $dark-blue;
      }
    }
    .row-dark {
      background-color: white;
      color: black;
      font-weight: 600;
    }
    .table-question {
      width: 10%;
      border-right: 1px solid $light-grey-200-solid;
      border-left: 1px solid $light-grey-200-solid;
      border-bottom: 1px solid $light-grey-200-solid;
    }
    .table-section {
      width: 5%;
      border-bottom: 1px solid $light-grey-200-solid;
    }
    &-label-row {
      width: 3%;
      border-bottom: 1px solid $light-grey-200-solid;
    }
    &-label-row-grey {
      background-color: $light-grey;
    }
    &-label-comments {
      width: 67%;
      border-bottom: 1px solid $light-grey-200-solid;
      border-left: 1px solid $light-grey-200-solid;
      background-color: white;
    }
    &-label-header {
      width: 3%;
    }
  }
  .separator-row
  {
    padding: 0px;
    margin: 0px;
  }
  .separator-cell
  {
    padding: 1px;
    margin: 0px;
    border-bottom: 1px solid $light-grey-200-solid;
  }
  .header-section
  {
    border-right: 1px solid $light-grey-200-solid;
  }
  .header-success-factor
  {
    border-right: 1px solid $light-grey-200-solid;
  }
  .header-notes
  {
    border-left: 1px solid $light-grey-200-solid;
  }
}

table.no-radius td {
  border-radius: 0 !important;
}

.table-responsive tr:last-child td:last-child {
  border-bottom-right-radius: 25px;
}
.table-responsive tr:last-child td {
  border-bottom: none;
}
.table-responsive .table-section:last-child {
  border-bottom: none !important;
}
.table-responsive tr:last-child td:first-child {
  border-bottom-left-radius: 25px;
}
.table-responsive tbody {
  border-radius: 10px;
}
.table-responsive tr:last-child {
  border-radius: 10px;
}
@include media-breakpoint-up(md) {
  .table-responsive {
    overflow-x: visible;
  }
}

@include media-breakpoint-down(md) {
  .table-responsive {
    overflow-x: visible;
  }
}

tr.dark td {
  background: #eee;
  font-weight: bold;
}
