.select {
    position: relative;
    select {
        width: 100%;
        //font-size: 16px;
        text-indent: 5px;
        outline: none;
        height: 38px;
        background: #fff;
        border: 1px solid $dark-blue-200;
        border-radius: $button-border-radius;
        padding-left: 10px;
        padding-right: 10px;
        -webkit-appearance:none;
        -moz-appearance:none;
        appearance:none;
        cursor:pointer;
    }
    &__icon {
        pointer-events: none;
        position: absolute;
        top: 10px;
        right: 15px;
    }
    &__text {
        display: block;
        color: $text;
        letter-spacing: 0;
        margin-bottom: 0;
    }
}
