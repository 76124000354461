body {
    font-family: 'Open Sans'    , sans-serif;
}

h1,h2,h3,h4,h5,h6{
    font-weight:bold!important;
    color: $dark-blue;
}

p {
    color: $dark-blue;
    font-size: $base-font-size;
}

.text-brand-primary {
    color: $secondary;
}

.text-dark-blue {
    color: $dark-blue;
}

.text-dark-blue-200 {
    color: $dark-blue-200;
}

.text-dark-blue-600 {
    color: $dark-blue-600;
}

.text-red {
    color: $red;
}

.text-amber-red {
    color: $red-amber;
}

.text-amber {
    color: $amber;
}

.text-green {
    color: $icon-color;
}

.text-green-amber {
    color: $amber-green;
}

.text-large {
    font-size: 18px;
}

.text-medium {
    font-size: 15px;
}

.text-small {
    font-size: 13px;
}

.text-bold {
    font-weight: 600;
}

i{
    color:#ffffff;
}