/*
 * Sidebar
 */

@media (max-width: 1000px) {
    .sidebar {
        top: 5rem;
    }
    .assessment-sidebar .nav-item {
        margin-left: 5px !important;
    }
}

.sidebar {
    &__title {
        white-space: normal;
    }
    position: fixed;
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 0;
    padding: 48px 0 0;
    background: var(--primary);
    &__image {
        width: 84px;
        height: 84px;
        border-radius: 25%;
        border: 2px solid white;
        background-color: $dark-blue;
        cursor: pointer;
    }
    &__title {
        font-size: 21px;
    }
    .navbar-brand {
        position: absolute;
        top: -2em;
        padding-top: .75rem;
        padding-bottom: .75rem;
        cursor: pointer;
    }
}

.sidebar-org-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    &__text {
        font-size: 14px;
        font-weight: bold;
        position: absolute;
        z-index: 2;
        text-align: center;
        width: 75px;
    }
}

@media (max-width: 767.98px) {
    .sidebar {
        top: 5rem;
    }
}

@media (max-width: 992px) {
    .sidebar-sticky {
        margin-right: -5px;
    }
}
#sidebarMenu {
    overflow: hidden;
}
.sidebar-sticky {
    position: relative;
    top: 0;
    height: 100vh;
    padding-top: .5rem;
    //overflow-x: hidden;
    //overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
    .sidebar-sticky {
        position: -webkit-sticky;
        position: sticky;
    }
}

.sidebar-heading {
    font-size: .75rem;
    text-transform: uppercase;
    font-weight: 300 !important;
    margin-left: 36px;
}
