.modal {
    &-header {
        border-bottom: 0;
        //padding-left: 2em;
    }
    &-body {
        padding-top: 0.5em;
    }
    &-footer {
        border-top: 0;
    }
}


.invite-modal {
    min-width: 600px;
    .modal-body {
        padding:0 20px 20px 20px;
    }
}
