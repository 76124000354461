@import '../variables';
textarea {
    width: 100%;
}

.alert {
    border-radius: 35px !important;
}

.invalid {
    input,
    textarea {
        border-color: $red !important;
    }
}

.input-container {
    //font-size: $input-font-size;
    line-height: $input-line-height;
    //display: inline-block;
    position: relative;
    transition: height 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    &.form-control {
        &.invalid {
            border-color: $alert-danger-border;
        }
    }
    input.input {
        background-color: #fff;
        height: 38px;
        border-radius: $button-border-radius;
        border: 1px solid $dark-blue-200;
        outline: none;
        box-shadow: none;
        background-image: none;
        -webkit-appearance: none;
        background-image: none;
        &:read-only {
            color: #777;
        }
    }
    textarea.text-area {
        background-color: #fff;
        // height: 38px;
        border-radius: $button-border-radius;
        border: 1px solid $dark-blue-200;
        outline: none;
        box-shadow: none;
        background-image: none;
        -webkit-appearance: none;
        background-image: none;
        &:read-only {
            color: #777;
        }
    }
    label {
        color: $dark-blue;
        margin-bottom: 0;
    }
    input,
    input:-webkit-autofill,
    textarea:-webkit-autofill,
    select:-webkit-autofill {
        //font-weight: $input-font-weight;
        //-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        //padding: 0px;
        padding-left: 10px;
        //position: relative;
        width: 100%;
        //border: none ;
        //border-width: 0;
        //outline: none ;
        //box-shadow: none ;
        //background-image: none ;
        //-webkit-appearance: none ;
        //background-image: none;
        //color: rgba(0, 0, 0, 0.870588);
        //height: 100%;
        &::-webkit-input-placeholder {
            font-weight: 100;
        }
    }
    &.error {
        input {
            background-color: $red;
        }
    }
    &.focused {
        input {
            border: 1px solid $secondary;
        }
    }
    input.input:not([value='']) {
        background-color: rgb(232, 240, 254) !important;
    }
}

.custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
}

.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: $custom-file-height;
    margin: 0;
    opacity: 0;
    height: 92px;
    cursor: pointer;
}

.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    display: flex;
    width: 100%;
    height: 92px;
    padding: 32px 82px 31.308px 82px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #CAD2D6;
    background: #D4D4D4;
}

.blue-background {
    background: var(--primary);
}

.color-picker-input input {
    padding: 0;
}

.ColorPickerComponent_colorPicker__37dCF {
    background-color: #fff;
    height: 38px;
    border-radius: $button-border-radius !important;
    border: 1px solid $dark-blue-200;
    outline: none;
    box-shadow: none;
    width: 100% !important;
    max-width: 100% !important;
}

.color-picker-input input {
    padding: 0;
}

// new
.rainge-points {
    font-size: 12px;
    margin-top: -3px;
    padding-left: 10px;
    // padding-right: 10px;
}

input[type="range"] {
    /* Customize the appearance of the slider */
    -webkit-appearance: none;
    appearance: none;
    height: 10px;
    border-radius: 5px;
    background: #d3d3d3;
    /* Default background color of the slider */
    outline: none;
    opacity: 0.7;
    transition: opacity 0.2s;
    padding-left: 0;
}

input[type="range"]:hover {
    opacity: 1;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px !important;
    height: 20px !important;
    border-radius: 50%;
    background: $secondary;
    /* Color of the slider thumb */
    cursor: pointer;
    border: none;
}

input[type="range"]::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: $secondary;
    /* Color of the slider thumb for Firefox */
    cursor: pointer;
}

textarea.input {
    background-color: #fff;
    border-radius: 15px;
    border: 1px solid rgba(18, 51, 64, 0.25);
    outline: none;
    box-shadow: none;
    background-image: none;
    -webkit-appearance: none;
    background-image: none;
    padding: 13px;
}