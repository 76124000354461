.dropdown-nav-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    color: black;
    z-index: 10000;
    background-color: white;
    width: 255px;
    border-radius: 25px;
    border-color: rgba(18, 51, 64, 0.2);
    border-width: 1px;
    justify-content: space-evenly;
    padding: 24px 28px;
    border-style: solid;
    i {
        color: $secondary;
    }
}

.dropdown-menu.show {
    overflow: hidden;
}

.disabled {
    opacity: 50%;
    cursor: not-allowed;
}