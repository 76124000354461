.navbar-toggler {
  left: 1em;
  position: relative;
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  }
}

.assessment-sidebar {
  &__nav-container {
    background-color: $primary;
  }
  .nav-item {
    color: white;
    padding: .75em;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    position: relative;
    white-space: nowrap;
    i {
      color: white;
    }
    span {
      color: white;
      position: relative;
      z-index: 200;
    }
    .active-shape {
      display: none;
    }
    &.active {
      background-color: #fafafa;
      i {
        color: $secondary;
      }
      span {
        color: $dark-blue;
      }
      .active-shape {
        display: block;
        width: 26px;
        height: 84px;
        position: absolute;
        right: -5px;
        top: -15px;
        z-index: 100;
      }
    }
  }
}

.assessment-progress {
  .progress {
    border-radius: 1em;
    background-color: $secondary-200;
    .progress-bar {
      background-color: $secondary;
    }
  }
  p {
    color: $dark-blue-600;
  }
  &--complete {
    background-color: $secondary;
    color: white;
    p {
      color: white;
    }
    .progress-bar {
      background-color: white !important;
    }
  }
}

@media screen and (max-width: 1417px)  {
  .assessment-sidebar {
    .nav-item {
      white-space: nowrap;
      span {
        font-size: 13px;
      }
    }
  }
}

.logo-block{
  max-width: 323px;
  width: 100%;
  display: flex;
  justify-content: center;
}