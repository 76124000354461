.input {
  &__checkbox {
    display: block;
    position: relative;
    padding-left: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      &:checked ~ .checkmark {
        background-color: $secondary;
        border: 1px solid $secondary;
        &:after {
          display: block;
        }
      }
    }
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: transparent;
      border-radius: 4px;
      border: 1px solid $dark-blue-200;
      &:after {
        content: '';
        position: absolute;
        display: none;
        left: 7px;
        top: 4px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
    &:hover input ~ .checkmark {
      border-color: darken($dark-blue-200, 10);
    }
    &--readonly {
      input {
        &:checked ~ .checkmark {
          background-color: $dark-blue-200;
          border: 1px solid $dark-blue-200;
        }
      }
    }
  }
  &__radio {
    input[type='radio'] {
      display: none;
      &:checked ~ label {
        &:before {
          background-color: white;
          border: 6px solid $secondary;
        }
      }
    }
    label {
      display: inline-flex;
      cursor: pointer;
      margin-bottom: 0;
      &:before {
        content: '';
        display: inline-block;
        min-width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid $dark-blue-200;
        top: 3px;
        position: relative;
        margin-right: 10px;
      }
    }
  }
}

.react-datepicker__input-container {
  input {
    opacity: 1 !important;
    height: 38px;
    border-radius: 20px;
    width: 100%;
    padding-left: 10px !important;
    &::-webkit-input-placeholder {
    }
  }
}

.react-datepicker {
  background-color: #fff;
  border: none !important;
  border-radius: $base-border-radius !important;
  box-shadow: 0 6px 13px 0 rgba(52, 48, 144, 0.1);
  display: flex !important;
  &__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected {
    background-color: $secondary !important;
    color: white !important;
  }
  &__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 95px !important;
  }
  &__month-container {
  }
  &__time-container {
    //border-left: 1px solid $lavender !important;
    margin-left: 15px !important;
  }

  &__header {
    background-color: transparent !important;
    border-bottom: none !important;
    border-top-left-radius: $base-border-radius !important;
    border-top-right-radius: $base-border-radius !important;
  }
  &__navigation--next {
    border-left-color: $secondary !important;
  }
  &__navigation--previous {
    border-right-color: $secondary !important;
  }
  &__day--selected {
    background-color: $secondary !important;
    color: white !important;
  }
  &__triangle {
    display: none;
  }
  &__current-month {
    //color: $oxford-blue !important;
  }
  &__day-name {
    //color: $light-steel-blue !important;
  }
  &__day {
    //color: $oxford-blue;
  }
  &__day--today {
    color: white !important;
  }
}

.btn-tab {
  color: $dark-blue-600;
  font-weight: bold;
  font-size: 20px;
  &:hover {
    color: darken($dark-blue-600, 20);
  }
  &:focus {
    box-shadow: none;
  }
  &.tab-active {
    background: $secondary-200 !important;
    color: $secondary !important;
    border: 1px solid $secondary-200-solid !important;
    border-radius: $button-border-radius !important;
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

.dropdown {
  z-index: auto;
}

.dropdown-menu {
  width: 100%;
  &.show {
    border-radius: $card-border-radius;
  }
}

.select-search__input {
  width: 100%;
  background-color: #fff;
  height: 38px;
  border-radius: $button-border-radius;
  border: 1px solid $dark-blue-200;
  outline: none;
  box-shadow: none;
  background-image: none;
  -webkit-appearance: none;
  background-image: none;
  padding-left: 15px;

  &:read-only {
    color: #777;
  }
  &:not([value='']) {
    background-color: rgb(232, 240, 254);
  }
}

.select-search__select {
  width: 100%;
  &.show {
    border-radius: $card-border-radius;
  }
}

.select-search__options {
  list-style: none;
  border-radius: $card-border-radius;
  border: 1px solid $dark-blue-200;
  max-height: 200px;
  overflow: scroll;
}

.select-search__option {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
