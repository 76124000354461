@font-face {
  font-family: 'mast-icon';
  src: url('/fonts/mast-icon.eot?o6tn12');
  src: url('/fonts/mast-icon.eot?o6tn12#iefix') format('embedded-opentype'), 
       url('/fonts/mast-icon.ttf?o6tn12') format('truetype'), 
       url('/fonts/mast-icon.woff?o6tn12') format('woff'), 
       url('/fonts/mast-icon.svg?o6tn12#mast-icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.circle {
  &__white {
    background-color: white;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    border: 3px solid #aae2e6;
    color: #FFFFFF;
    font-size: 11px;
    letter-spacing: -0.08px;
    line-height: 13px;
    text-align: center;
  }
  &__amber {
    background-color: $amber;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    border: 3px solid $amber-200;
    color: #FFFFFF;
    font-size: 11px;
    letter-spacing: -0.08px;
    line-height: 13px;
    text-align: center;
  }
  &__red {
    background-color: $red;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    border: 3px solid $red-200;
    color: #FFFFFF;
    font-size: 11px;
    letter-spacing: -0.08px;
    line-height: 13px;
    text-align: center;
  }
  &__green {
    background-color: $green;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    border: 3px solid $green-200;
    color: #FFFFFF;
    font-size: 11px;
    letter-spacing: -0.08px;
    line-height: 13px;
    text-align: center;
  }
  &__green-amber {
    background-color: $green;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    border: 3px solid $green-200;
    color: #FFFFFF;
    font-size: 11px;
    letter-spacing: -0.08px;
    line-height: 13px;
    text-align: center;
  }
  &__amber-red {
    background-color: $red-amber;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    border: 3px solid $red-amber-200;
    color: #FFFFFF;
    font-size: 11px;
    letter-spacing: -0.08px;
    line-height: 13px;
    text-align: center;
  }

  &__dark-blue-200 {
    background-color: $dark-blue-200;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 11px;
    letter-spacing: -0.08px;
    line-height: 13px;
    text-align: center;
  }
}





[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "mast-icon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-analytics-green:before {
  content: "\e900";
}

.icon-brand-green:before {
  content: "\e901";
}

.icon-bulls-eye-green:before {
  content: "\e902";
}

.icon-check-circle:before {
  content: "\e903";
}

.icon-home-green:before {
  content: "\e904";
}

.icon-logo-green:before {
  content: "\e905";
}

.icon-org-chart-green:before {
  content: "\e906";
}

.icon-pie-chart-green:before {
  content: "\e907";
}

.help-text-tooltip{
  color : $icon-color;
}

.eye-icon-color{
  color : $secondary;
}

