.question {
  &__number {
  }
}

.answers {
  &__container {
    background-color: $light-grey-200;
    border-bottom-left-radius: $card-border-radius;
    border-bottom-right-radius: $card-border-radius;
    padding: 1em;
  }
}

.answer-list-item {
  background: #ffffff;
  border: 1px solid $dark-blue-200;
  border-radius: 20px;
  padding: 0.5em 0.5em 0.5em 1em;
  margin-bottom: 0.5em;
  display: flex;
  align-items: center;
  &.active {
    background-color: $dark-blue;
    border: 1px solid $secondary;
    color: white;
  }
  &:last-child {
    margin-bottom: 0;
  }
  pointer-events: stroke;
}

.terms {
  background: #ffffff;
  margin-bottom: 0.5em;
  display: flex;
  align-items: center;
  &.active {
    background-color: $dark-blue;
    border: 1px solid $secondary;
    color: white;
  }
  &:last-child {
    margin-bottom: 0;
  }
  pointer-events: stroke;
}

$number-of-options: 5;

#form-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2em;
}

form {
  width: 90%;
  &.full-width {
    width: 100%;
  }
  //max-width: 500px;
  #form-title {
    margin-top: 0;
    font-weight: 400;
    text-align: center;
  }
  #amount-slider {
    display: flex;
    flex-direction: row;
    align-content: stretch;
    position: relative;
    width: 100%;
    height: 50px;
    user-select: none;
    &::before {
      content: ' ';
      position: absolute;
      height: 8px;
      width: 100%;
      width: calc(100% * (#{$number-of-options - 1} / #{$number-of-options}));
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #ccd3d6;
    }
    input,
    label {
      box-sizing: border-box;
      flex: 1;
      user-select: none;
      cursor: pointer;
    }
    label {
      display: inline-block;
      position: relative;
      width: 20%;
      height: 100%;
      user-select: none;
      &::before {
        content: attr(data-option-text);
        position: absolute;
        left: 50%;
        padding-top: 10px;
        transform: translate(-50%, 45px);
        font-size: 14px;
        letter-spacing: 0.4px;
        font-weight: 400;
        white-space: nowrap;
        opacity: 0.85;
        transition: all 0.15s ease-in-out;
      }
      &::after {
        content: ' ';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 30px;
        height: 30px;
        border: 2px solid #ccd3d6;
        background: #ccd3d6;
        border-radius: 50%;
        pointer-events: none;
        user-select: none;
        z-index: 1;
        cursor: pointer;
        transition: all 0.15s ease-in-out;
      }
      &:hover::after {
        transform: translate(-50%, -50%) scale(1.25);
      }
    }
    input {
      display: none;
      &:checked {
        + label::before {
          font-weight: 800;
          opacity: 1;
        }
        + label::after {
          border-width: 4px;
          transform: translate(-50%, -50%) scale(0.75);
        }
        ~ #amount-pos {
          opacity: 1;
        }
        @for $i from 1 through $number-of-options {
          &:nth-child(#{$i * 2 - 1}) ~ #amount-pos {
            left: #{($i * 20%) - 10%};
          }
        }
      }
    }
    #amount-pos {
      display: block;
      position: absolute;
      top: 50%;
      width: 30px;
      height: 30px;
      //background: #fff;
      border-radius: 50%;
      transition: all 0.15s ease-in-out;
      transform: translate(-50%, -50%);
      //border: 2px solid #fff;
      opacity: 0;
      z-index: 2;
      background-color: $secondary;
      border: 6px solid white;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05),
        0 2px 1px -1px rgba(0, 0, 0, 0.03), 0 1px 1px 0 rgba(0, 0, 0, 0.04);
    }
  }
  &:valid {
    #amount-slider {
      input {
        + label::before {
          transform: translate(-50%, 45px) scale(0.9);
          transition: all 0.15s linear;
        }
        &:checked + label::before {
          transform: translate(-50%, 45px) scale(1.1);
          transition: all 0.15s linear;
        }
      }
    }
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
    width: 24px;
    opacity: 1;
    margin-right: 12px;
  }
  to {
    transform: rotate(360deg);
    width: 24px;
    opacity: 1;
    margin-right: 12px;
  }
}
