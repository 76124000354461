//Overriding rc-slider module styles
.rc-slider-step {
  background: $dark-blue-200-solid !important;
  height: 12px !important;
}

.rc-slider-handle {
  width: 18px !important;
  height: 18px !important;
  margin-left: 4px !important;
  margin-top: -4px !important;
  border: solid 3px #fff !important;
  background-color: $secondary !important;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.3),
    0 1px 1px 0 rgba(0, 0, 0, 0.4) !important;
}

.rc-slider-dot {
  background: $dark-blue-200-solid !important;
  border: 2px solid $dark-blue-200-solid !important;
  width: 18px !important;
  height: 18px !important;
}

.rc-slider-mark {
  top: 24px !important;
}

.rc-slider-mark-text {
  color: $dark-blue !important;
  max-width: 85px !important;
  &-active {
   font-weight: bold !important;
  }
}

