body,
html {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

body {
  // background: linear-gradient(to left top, #4EACC5, #267493) fixed;
  background: $primary;
}

.body-light {
  background-color: #fafafa;
  height: 100%;
}

.main {
  background-color: #fafafa;
  border-radius: 20px 0 0 20px;
  margin-top: 3.5em;
  &__thanks-page {
    //height: 91.5vh;
    height: calc(100vh - 4.5em);
  }
}

.project-stage {
  .col {
    &:first-child {
      .stage {
        border-top-left-radius: 100px;
        border-bottom-left-radius: 100px;
      }
    }
    &:last-child {
      .stage {
        border-top-right-radius: 100px;
        border-bottom-right-radius: 100px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .project-info-title-spacer {
    margin-bottom: 2em;
  }
}
