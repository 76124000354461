.pointer-none {
    pointer-events: none !important;
}
.border-radius {
    &__base {
        border-radius: $base-border-radius !important;
    }
}

.background-blue-gradient {
    background-image: linear-gradient(225deg, #4EACC5 0%, $primary 100%);
}

.background-dark-blue-200 {
    background-color: $dark-blue-200;
}

.height-100 {
    height: 100vh !important;
}

.opacity {
    &__600 {
        opacity: 0.6;
    }
}

.logo {
    width: 296px;
}