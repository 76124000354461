.badge {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: .25em 0;
    &-light {
        background-color: white;
        border: 1px solid $dark-blue-200;
        color: $dark-blue;
    }
}