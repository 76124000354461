.card {
    background: white;
    border: 1px solid $dark-blue-200;
    border-radius: 25px;
    padding: 1.5em;
    &--error {
        border: 1px solid $red;
    }
}

.info-card {
    &__title {
        font-size: 13px;
        color: $dark-blue;
    }
    &__icon {
        //position: absolute;
        //right: 40px;
    }
    &__value {
        color: $dark-blue;
    }
}

.stage {
    background: $primary-200;
    opacity: $alpha;
    color: #fff;
    height: 36px;
    font-size: 13px;
    font-weight: 600;
    align-items: center;
    display: flex;
    padding-left: 2em;
    &--active {
        background-color: $secondary;
        color: white;
        opacity: 0.9;
    }
    &--complete {
        background-color: $secondary-200;
        color: $secondary;
    }
}

@include media-breakpoint-down(lg) {
    .info-card {
        &__value {
            font-size: 1.5em;
        }
    }
}